.boxDataBase{
    text-transform: uppercase;
    font-weight: 600;
    height: 10px;
    width: 55%;
    height: 50px;
    min-width: 222px;
    margin: 0 auto;
    margin-bottom: 25px ;
    border: 1px solid var(--cor-txt-navbar);
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
}
.imgDatabase{
    transition: 2s;
    transform: rotate(90deg);
    margin-left: 10px;
}
.imgDatabaseOpen{
    transition: 2s;
    transform: rotate(270deg);
    margin-left: 10px;
}
.cardGrup{
    display: flex;
    flex-direction: column;
    min-width: 300px;
    width: 50%;
    margin: 0 auto;
    border-left: 1px solid var(--cor-txt-navbar);
    transition-property: opacity, left, top, height;
    transition-duration: 3s, 5s;
    margin-bottom: 20px;
}
.noneResult{
    margin: 0 auto;
    padding: 25px;
    text-transform: uppercase;
    padding-top: 1px;
    padding-right: 42px;
    color: #1b1b1b;
    min-width: 40px;
    display: block;
    float: left;
}
.rotesPage{
    padding-left: 33px;
    color: #000000a6;
    margin: 0 auto;
    max-width: 1350px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
}
.searchPageCenter{
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 52px 0px;

}
.item{

    width: auto;
    margin-bottom: 46px;
    /* height: 50px; */
}
.dateCard{

    margin-left: 25px;
    position: relative;
    margin-bottom: 42px;
    color: #19232d;
    
   
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    float: left;
    padding: 0 15px;
    line-height: 28px;
    border: 1px solid var(--cor-txt-navbar);
    margin-top: -14px;
    margin-bottom: 28px;
}
.dateCard:before {
    display: block;
    position: absolute;
    top: 13px;
    left: -26px;
    width: 25px;
    height: 1px;
    background: var(--cor-txt-navbar);;
    content: " ";
}
.dateText{

}
.titleCard{
    padding: 49px;
    padding-left: 226px;;
}
.titleText{
    text-transform: uppercase;
    padding-top: 1px;
    padding-right: 42px;
    color: #000000;
    min-width: 40px;
    display: block;
    float: left;
}

@media screen and (max-width: 1255px) {
    .titleCard{
        padding: 20px;
        padding-left: 59px;
    }
  
    }