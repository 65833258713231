.box_news {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
}

.news_item {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 320px;
}

.news_item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.news_img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.news_content {
  padding: 15px;
  text-align: left;
}

.news_title {
  font-size: 0.86rem;
  font-weight: 600;
  color: #333;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  text-decoration: none;
}

.news_date,
.news_category,
.news_views {
  font-size: 0.875rem;
  color: #888;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}

.news_text {
  font-size: 0.875rem;
  color: #555;
  font-weight: 500;
}

.news_item:hover .news_title {
  color: #1a73e8;
}

.cat_views {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
