.card {
  border: 1px solid #ccc;
  border-radius: 6px;
  margin: 10px 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
  gap: 10px;
  flex-wrap: nowrap;
  color: #424242;
  font-weight: 500;
}

.ultima_movimentacao {
  position: absolute;
  right: 10px;
  top: 25px;
  padding: 4px 8px;
  border-radius: 6px;
}

.ultima_movimentacao_interno {
  display: none;
}

@media screen and (max-width: 920px) {
  .card {
    flex-wrap: wrap;
  }
  .ultima_movimentacao {
    top: 30px;
  }
}

@media screen and (max-width: 920px) {
  .box {
    flex: 1 !important;
  }

  .card {
    flex-direction: column;
  }

  .ultima_movimentacao {
    display: none;
  }
  .ultima_movimentacao_interno {
    display: block;
  }
}
.card b {
  color: #141414;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.sequencia {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.info_box {
  display: flex;
  flex-direction: column;
  min-width: max-content;
}

.greenStatus {
  max-width: 170px;
  padding: 5px 10px;
  border: 1px solid #34c759;
  border-radius: 4px;
  background-color: #34c75910;
  font-weight: 600;
  color: #424242;
  text-align: center;
  word-break: break-all;
}

.yelowStatus {
  word-break: break-all;
  text-align: center;
  max-width: 170px;
  padding: 5px 10px;
  border: 1px solid #ffcc00;
  border-radius: 4px;
  background-color: #ffcc0010;
  font-weight: 600;
  color: #424242;
}

.redStatus {
  word-break: break-all;
  text-align: center;
  max-width: 170px;
  padding: 5px 10px;
  border: 1px solid #ff3b30;
  border-radius: 4px;
  background-color: #ff3b3010;
  font-weight: 600;
  color: #424242;
}

.identificacao {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: max-content;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.container_item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px 20px;
  flex-wrap: wrap;
}

.container_icone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.container_icone {
  max-width: 780px;
}

.objeto {
  display: flex;
  min-width: max-content;
  align-items: flex-start;
  justify-content: start;
  text-align: left;
  flex-direction: row;
  flex-wrap: wrap;
}

.box a {
  text-transform: uppercase;
}

.btn {
  padding: 10px 15px;
  background-color: #ff9500;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
  box-shadow: 0px 0px 2px 2px #00000015;
}

.btn:hover {
  box-shadow: 1px 1px 3px 2px #00000030;
  color: #fff;
}

/* LISTAGEM  */

.container_lista {
  background-color: #fff;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 1px #ccc;
}
