.gridPage{
    display: grid;  
    gap: 10px;
    row-gap: 35px;
    align-items: center;
    grid-template-areas: 
    'buttons'
    'buttons'
    'buttons'
    'buttons'
    'buttons'
    'buttons'
    'buttons'
    'buttons'
    'buttons'
    'buttons'
    'buttons'
    'buttons'
    'footer'
    ;
}
#buttonsServices{
    grid-area: buttons;
}
#ancoraRodape {
    grid-area: footer;
}

.gridEmpreendedor{
    display: grid;
    justify-items: stretch;
    justify-content: center;
    grid-template-columns: 28% 28% 28% 28%;
    grid-template-rows: 100% 100% 100% 100%;
    column-gap: 50px;
    row-gap: 10px;
    max-width: 1200px;
    margin: 0 auto;
}
.btnServicosEmpreendedor{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    max-width: 279px;
    max-height: 158px;
    padding: 0;
    margin:0;
    border-radius: 15px;
    background-color:  rgb(var(--RGB1-sombra-card-sala-empreendedor), var(--RGB2-sombra-card-sala-empreendedor), var(--RGB3-sombra-card-sala-empreendedor),0.428);
    color: var(--cor-btn-letra-sala-empreendedor);
    text-transform: uppercase;
    text-align: center;

    -webkit-box-shadow: 6px 14px 15px -11px rgba(0,0,0,0.43);
    -moz-box-shadow: 6px 14px 15px -11px rgba(0,0,0,0.43);
    box-shadow: 6px 14px 15px -11px rgba(0,0,0,0.43);
}
.btnServicosEmpreendedor:hover{
    scale: 1.05;
    transition: 1s ;
    color: var(--cor-btn-hover-letra-sala-empreendedor)
}

@media (max-width: 1399px)
{  
    .gridEmpreendedor{
        column-gap: 10px;
        row-gap: 10px;
    }
  
}

@media (max-width: 1245px)
{  
    .gridEmpreendedor{
    grid-template-columns: 25% 25% 25%;
    grid-template-rows: 70% 70% 70% 70% 70% 70%;
    column-gap: 10px;
    row-gap: 10px;
    }
  
}
@media (max-width: 700px)
{  
    .gridEmpreendedor{
    grid-template-columns: 40% 40%;
    grid-template-rows: 55% 55% 55% 55% 55% 55%; 
    column-gap: 10px;
    row-gap: 10px;
    }
  
}
@media (max-width: 500px)
{  
    .gridEmpreendedor{
    grid-template-columns: 40% 40%;
    grid-template-rows: 40% 40% 40% 40% 40% 40% 40% 40%; 
    column-gap: 10px;
    row-gap: 10px;
    }
  
}
