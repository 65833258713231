.boxDetail{

    background-color: var(--diary-box-detail-fundo);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
    padding:15px;
    width: 60%;
    height: 60%; 
    position: fixed;
    z-index: 1200;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}
.headerDetail{
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
}
.headerDetail .iconeDetail{
    fill: var(--diary-box-detail-icone);
    width: 16px;
    height: 16px; 
    margin:0;
    text-transform: uppercase;
}

.bodyDetail{
/* scroll- */
    font-size: 0.8rem;
    text-transform: uppercase;
    height: 90%;
    overflow: auto;
    text-align: justify;
    /* display: grid; */

}
.bodyDetail p{
    padding-right: 10px;
}

.noneResult{
    align-items: center;
    justify-content: center;
    display: flex;
    height: 7rem;
    color: var(--diary-table-txt);
}
.boxBodyColum{
    grid-column-gap: 10px;
    display: grid;
    text-transform: uppercase;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 10px 0;
}
.boxBodyColum2{
    grid-column-gap: 10px;
    display: grid;
    text-transform: uppercase;
    grid-template-columns: 1fr 1fr;
    padding: 10px 0;
}
.boxBodyColum1{
    grid-column-gap: 10px;
    display: grid;
    text-transform: uppercase;
    grid-template-columns: 1fr;
    padding: 10px 0;
}


@media screen and (max-width: 660px){
    .boxDetail{
        
        width: 80%;
        height: 70%; 
        
    }
   
    .bodyDetail{
    font-size: 0.7rem;
    text-transform: uppercase;
    height: 90%;
    overflow: auto;
    text-align: justify;
    
    }
    .bodyDetail p{
        padding-right: 10px;
    }

}

@media screen and (max-width: 990px){
   
    .iconeCard{
        width: 22px;
        height: 22px;
        fill:var(--diary-card-icone);
        margin-bottom: 5px;
    }
    .bodyTxt{
        padding-left: 10px;
        /* font-size: 12px; */
        font-size: 0.7rem;
        
    }
   
    .iconeActions{
        width: 16px;
        height: 16px;
        
    }
    .boxDetail{
    
    }

}
@media screen and (max-width: 470px){
   
    .boxBodyColum{
        font-size: 0.7rem;
        grid-column-gap: 10px;
        display: grid;
        text-transform: uppercase;
        grid-template-columns: 1fr;
        padding: 0 0;
        
    }
    .boxBodyColum2{
        font-size: 0.7rem;
        grid-column-gap: 10px;
        display: grid;
        text-transform: uppercase;
        grid-template-columns: 1fr;
        padding: 0 0;
        
    }
    .boxBodyColum1{
        font-size: 0.7rem;
        grid-column-gap: 10px;
        display: grid;
        text-transform: uppercase;
        grid-template-columns: 1fr;
        padding: 0 0;
    }
    

}

