.card{
    display: grid;
    grid-template-columns: 1fr;
    padding: 20px;
    margin: 0 auto;
    justify-items: center;
    height: 100vh;
    background-color: var(--cor-sub-lista-navbar);
    color: #000;
}
.txt{
  text-align: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.boxImg{
  display: flex;
  justify-content: center;
  padding: 26px;
  background-color: #ffffff94;
  border-radius: 15px 15px 0px 0px;
}
.img{
  width: 118px;
}
.bodyTxt{
  padding: 26px;
  background-color: #ffffff94;
  color: #000;
  border-radius: 0px 0px  15px 15px;

}
