.container_butons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.button_rotes {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  border-radius: 14px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 18rem;
  height: 7.9rem;
}

.button_rotes:hover {
  background-color: #f1f1f3;
  transition: 0.4s;
  transform: scale(1.1);
}

.button_rotes p {
  color: #424242;
  font-weight: 500;
  text-align: center;
  font-size: 1rem;
}

.acoes {
  display: flex;
  gap: 10px;
}

.acoes b {
  border: 1px solid var(--cor-nome-portal);
  padding: 2px 8px;
  cursor: pointer;
  color: var(--cor-nome-portal);
}
