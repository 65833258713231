.boxOrganograma {
  margin: 0 auto;
  max-width: 1300px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 0px 0px 39px 0px
}
.cardInfoOrganograma {
  width: 70%;
  min-width: 300px;
  font-size: 1em;
  border-radius: 15px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  padding: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  height: 70%;
  border: rgb(255, 255, 255) solid 20px;
}
.boxTitle {
  display: flex;
  height: 20px;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-bottom: 3.4em;
  flex-direction: row;
}
.title{
    display: flex;
    align-items: center;
    padding-top: 28px;
    margin: 0px 1.5rem 0px;
}
.title h5{
  text-transform: uppercase;
  font-weight: 700;
  margin-left: 1rem;

}

.cardBody {
  overflow-y: auto;
  height: 87%;
  padding:1.0rem 2rem;
}

.textAtribuicao{
  padding: 0 20px;
}
.backgroundOutsideCard{
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tableOrganograma {
  /* max-width: 1201px; */
  margin: 0 auto;
}

.tableOrganograma .table>:not(caption)>*>* {
  text-align: center;
}
.subTitle{
    color: var(--cor-Title);
    margin: 0 0 0 10px;
}

.iconeSubTitle{
  color: var(--cor-Title);
}
.noneResult{
    color: var(--cor-Title);
    height: 7rem;
    display: flex;
    justify-content: center;
}

.msgAlertOrganograma{
  display: flex;
  align-items: center;
  color: #dc3545;
}
.msgAlertOrganograma p{
  margin: 0px 10px;
}
.msgAlertOrganograma svg{
  fill: #dc3545
}

  
@media screen and (max-width: 400px) {
  .boxOrganograma {
    margin-top: 40px;
  max-width: 1300px;
    height: 13%;
  }
}