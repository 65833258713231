.svgSubtype{
    fill: var(--cor-icone-buscar-atos-administrativos);
  
}
.titleSub{
    display: flex;
    color: var(--cor-icone-buscar-atos-administrativos);
    padding: 10px 0;
    align-items: center;
   
}
.titleSub:hover{
    cursor: pointer;

   
}
.titleSub h5{
    margin: 0
}
.titleSub h5:hover{
   text-shadow: black  0 0 1px
}
.titleSub svg:hover{
    text-shadow: black  0 0 1px;
    box-shadow: black  0 0 1px;
    border-radius: 15px;
 }