.inforDiaryTxtSection{
    margin: 0 auto;
    max-width: 1350px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;

}.InforDiary{
    max-width: 1194px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.InforDiary div{
    padding: 20px;
    border-radius: 15px;
    box-shadow: rgba(6, 32, 110, 0.05) 0px 6px 24px 0px,  var(--cor-sub-lista-navbar) 0px 0px 0px 1px;
}
.InforDiary div:hover{
    cursor: pointer;
    transition: 0.4s;
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.261) 0px 6px 24px 0px,  var(--cor-sub-lista-navbar) 0px 0px 0px 1px;
}

.InforDiary div:first-child{
    margin-right: 20px;
}
.boxInforDiary{
    background: #fff;
    border: 20px solid #fff;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0,0,0,.35);
    font-size: 1em;
    height: 70%;
    left: 50%;
    min-width: 300px;
    padding: 5px;
    position: fixed;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 70%;
    z-index: 1;
}.boxInforDiary .title{
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.boxInforDiary .txt{
    margin-top: 10px;
    height: 80%;
    overflow-y: auto;
    padding-right: 20px;
    text-align: justify;
}







.diary{
    max-width: 1194px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    
}
.item2 {
    display: flex;
    text-align: center;
    justify-content: center;
    width: 200px;
    margin: 12px 61px 12px 12px;
    box-shadow: rgba(6, 32, 110, 0.05) 0px 6px 24px 0px,  var(--cor-sub-lista-navbar) 0px 0px 0px 1px; 
    border-radius: 15px;
    padding-top: 5px;
    padding-bottom: 5px;

}
.item {
    display: flex;
    text-align: center;
    justify-content: center;
    width: 190px;
    margin: 12px 61px 12px 12px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
    padding-top: 5px;
    padding-bottom: 5px;

}
.img{
    width: 50px;
    margin: auto 0;
}
.img2{
    width: 60px;
    margin: auto 0;
}
/* .item:hover{
    
    background-color: rgba(185, 185, 185, 0.379);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    transition: 0.4s;
    transform: scale(1.1);
} */
.item2:hover{
    color:#0000;
    /* background-color: rgba(170, 166, 166, 0.278); */
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    transition: 1s;
    transform: scale(1.1);
}

.link{
    text-decoration: none;
    color: black;
    padding-left: 14px;
}
.link:hover{
    transition: 1s;

    color:rgb(79, 79, 79)
}
.diaryForms{
    padding: 5px;
    background-color: rgb(255 255 255);
    border: 2px solid #eeeeee;
    border-radius: 10px;
    width: 506px;
}
.form{
    display: flex;
    max-width: 1194px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

}
.txtTitleDiary{
    margin-bottom: 1px;
}
.divtxt{
    margin: 0 auto;
    max-width: 1132px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
}