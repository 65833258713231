.button-acessibilidade{
   
    min-width: 26px;
    text-align: center;
    border: 1px solid rgb(129, 129, 129);
    
    
    position: relative;
    cursor: pointer;
    background: rgb(175, 175, 175);
    color: rgb(0, 0, 0);
    border-radius: 3px;
    margin-left: .2rem;
}
.button-acessibilidade:hover{
  background: rgb(133, 132, 132);
  transition: 1s;
}
.button-acessibilidade:active {
  background: rgb(223, 223, 223);
  
}
.skip-icon {
    background-color: black;
    padding: 2px 0.5px 2px 0.5px;
    border-radius: 2px;
    margin: 5px;
}
.button-acessibilidade:focus-visible {
  border-color: aliceblue;  
  background-color: #e66e6e;
}
.logo-top {
      /* animation: is-rippling 3s ease alternate infinite; */
      width: 100px;
      height: auto;
      margin-right: 8px;
}
.nav-logo-left{
  width: 4rem;
  margin: auto;
}
hr:not([size]) {
  height: 1px;
  width: 100%;
}
.nav-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--cor-nome-portal);
}
.nav-logo-no-animation{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 7px;
  /* animation: has-show 4s ease forwards; */
  width: 100%;
    background-color: white;
}
.nav-logo {
   
    width: 100%;
    background-color: white;
    border-radius: 35px 35px 0px 0px;
    padding-top: 5px;
    padding-bottom: 13px;
    margin:0
    
}
.card-logo{
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    width: 38.95%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0);
    animation: has-show 4s ease forwards;

}
.div-navbar{ 
  align-items: center;
  padding-bottom: 0.2rem;
}
.div-navbar div.container-navbar{
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: #000000;
  background: var(--cor-acessibilidade);
}
.div-navbar a:hover{
  text-decoration: none;
  color:#070707;
  
}
.Title-Navbar {
  margin: 0px;
  margin: 0 0 3px 0px;

}

/* @keyframes has-show {
  from {
    opacity: 0.7;
    transform: translateX(-130px) scale(1.4);
    
  }
  to {
    opacity: 1;
    transform: translateX(0px) scale(1.1);
  }
}
*/
.container-back{
  animation: has-show-box 4s ease forwards;
}
/* animação bem vindos */
@keyframes has-show-box {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.line{
 background-color: var(--cor-nome-portal);
  border: 15px;
  opacity: 1.0;
}
.lista-primaria-navbar{
  /* font-size: .7rem; */
  font-weight: 550;
}


.list-navbar {
  list-style: none;
  padding: 0;   
  margin:0;
}
li.itens-navbar.itens.button-navbar:hover {
  background: #c4c5c5;
  transition: 0.5s;
}
.itens:hover a:hover { 
  background: #c4c5c5;
  border-color:#c4c5c5 ; 
  transition:0.5s; 
}
.itens:hover p:hover { background: #c4c5c5; transition:0.5s; }
.list-navbar:before,
.list-navbar:after {
  content: ""; /* 1 */
  display: table; /* 2 */
  width: 7em;
  
}
.list-navbar:after { clear: both;}
.itens:hover li { float: none; }
.itens:hover > ul {
  display: block;
  position: absolute;
  width: auto;
  /* left:-50px; */
  white-space:normal;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  /* sombra */
 
}

.list-navbar  li {
  display: block;
  /* position: relative; */
  /* top: 100%; */
  float: left;
  white-space: normal;

  /* left: -40px; */
}
.itens ul { 
  display: none; 
} 

/* texto sub-lista */
li.itens-navbar.itens.sub-list p{
  color: var(--cor-sub-lista-navbar-txt)
}
li.itens-navbar.itens.sub-list p:hover{
  color: var(--cor-sub-lista-navbar-txt-hover)
}
.sub-list a.drop-navbar{
  color: var(--cor-sub-lista-navbar-txt)
}
.itens:hover a:hover {  
  color: var(--cor-sub-lista-navbar-txt-hover);
}

 .list-navbar li a {
  display: block;
  padding: 0.5em;
  margin: 0;
  text-decoration: none;
  white-space:normal;  
  border: #000000 2px;
  color:#ffff;
  border-radius: 15px ; 
}
.list-navbar li p {
  display: block;
  padding: 0.5em;
  text-decoration: none;
  white-space:normal;
  color: var(--cor-txt-navbar);
  margin: 0;
}

.list-navbar ul ul li { 
  border-top: 0;
  
  /* sombra */ 
}
.list-navbar ul ul{ 
  left: 100%;
  top: 0;
}
/* 
.list-navbar li:last-child {
  color: #e310d5;
  border-radius: 0 0px 15px 15px;
} */


ul.lista-primaria-navbar li p.icon-drop.drop-navbar::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
ul.lista-secundaria-navbar li p.icon-drop.drop-navbar::after{
  display: inline-block;
  margin-left: 0.5em;
  vertical-align:baseline;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom:  0.3em solid transparent;
  border-left: 0.3em solid ;
}
ul.lista-secundaria-navbar li p.icon-drop.drop-navbar{
  width: 11.5rem;
}

 .box-accessible{ 
  display: flex;
  justify-content: flex-end;
  text-align: end;
  font-size: .7rem;
  margin: 0;
  padding: 0px;
  padding-right: 0.5em;
  font-size: 0.8em;
  padding-bottom: 1.1rem;
  padding-top: 0.2rem;
  background-color: var(--cor-acessibilidade);
}
.icon-menus-suspenso{
  display: none;
}.button-menus-suspenso button{
  display: none;
}

.desq{
  display: none;
} 
.new-nav:hover .desq{
  display: flex;
}
.new-nav h6::after{
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
 
  display: inline-block;
  margin-left: 0.5em;
  vertical-align:baseline;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
 .new-nav .desq .desq .link-sidebar{
 display: none;
}  
.new-nav li.desq li.desq:hover a.desq.link-sidebar{
  display: flex;
} 
ul.list-navbar.lista-primaria-navbar {
  font-size: 0.9rem;
 
} 

.button-menus-suspenso {
  display: none;
}


  

  .div-navbar{
    justify-content: space-around;
  }
  


/* Navbar CM */


.navbar{
  background-color: #B28E08;
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  /* width: 100%; */
  
  margin: 0;
  padding: 0;
 
}

.item1{
  background-color: #fdfdfd;
  border-radius: 0 0 153px 0;
  /* box-shadow: 0 1px 0 0 red; */
  height: 100%;
  font-size: 1.75rem;
}
.item1 a{
  text-decoration: none;
}
.item2{
  background-color: #B28E08;

}

.logo-top {
  margin: 5px;
  width: 100px;
  width: 127px;   
  height: auto;
  margin-right: 8px;
}
.card-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-around;
  margin: 0 auto;
  

}
.nav-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--cor-nome-portal);
  /* color: #B28E08; */
}
hr:not([size]) {
  height: 1px;
  width: 100%;
}
.line {
  background-color: var(--cor-nome-portal);
  /* background-color: #B28E08; */

  border: 15px;
  opacity: 1.0;
}
.Title-Navbar {
  margin: 0px;
  margin: 0 0 3px 0px;
}



/*

  Acessibilidade

*/

.acessibilidade{
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}
.iconeAcessibilidade {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fdfdfd;
  width: 35px;
  height: 35px;
  margin: 0;
  border-radius: 35px;
  margin-left: 9px; 

}
.iconeAcessibilidade img{
  width: 16px;
}
.buttons{
  display:flex;
}
.bu{
  background-color: #fdfdfd;
  width: 200px;
  height: 90px;
  margin: 2px;

}

.Link-drop {
  color: #2b2b2be0;
}
.Link-drop:hover {
  color: #4343435e;
}














@media screen and (max-width: 1070px) {
  .navbar-button-list {
    width: 661px;
}
  .container-menu-slider {
    width: 42rem;
    
}
.logo-top {
  width: 80px;
  height: 80px;
}
}
@media screen and (max-width: 770px) {
  .navbar-button-list {
    width: 441px;
}
  .container-menu-slider {
    width: 30rem;
    
}
.logo-top {
  width: 60px;
  height: 60px;
}
.Title-Navbar {
  font-size: 16px;
}
}
@media screen and (max-width: 620px) {
  .arrow-slider-menu {
    cursor: pointer;
    display: flex;
    position: absolute;
    margin-left: -15px;
    margin-right: -15px;
}
}
@media screen and (max-width: 600px) {
  .arrow-slider-menu {
    margin-left: -46px;
    margin-right: -36px;
}
  .container-menu-slider {
      width: 14rem;
      max-width: 250px;
  }
  .box-accessible{
    display: none;
  }
  .nav-logo {
    border-radius: 0px;
}
}