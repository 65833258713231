.inforDiaryTxtSection{
    margin: 0 auto;
    max-width: 1291px;
    width: 100%;
    padding:10px 10px;


}

.inforDiaryTxtSection scan.txtNone{
    
    font-size: 10pt;
    font-family: ArialMT !important;
}

.group{
    margin: 0 auto;
    max-width: 1300px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    padding-bottom: 15px;
}
.card{
    background-color: var(--diary-card);
    color: var(--diary-card-txt);
    border-radius: 15px;
    width: 300px;
    height: 190px;
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.titleTxt{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 10px;
    text-transform: uppercase;

}
.iconeCard{
    width: 32px;
    height: 32px;
    fill:var(--diary-card-icone);
    margin-bottom: 5px;
}
.bodyTxt{
    padding-left: 10px;
    text-transform: uppercase;
    font-size: 0.8rem;

}
.bodyTxt a{
    text-decoration: none;
    color: var(--diary-card-txt);
    
}
.bodyTxt a:hover{
    text-decoration: none;
    color: var(--diary-card-txt);
    text-shadow:2px 2px var(--diary-card-link-hover);

}
.numberDiary{
    font-size: 0.9rem;
    text-shadow: 2px 2px var(--diary-card-link-hover);
}
.iconeActions{
    width: 16px;
    height: 16px;
    fill:var(--diary-card-icone);
    margin-left: 10px;
}
svg.iconeActions:hover{
    scale: 1.2;
    cursor: pointer;
}
.boxDetail{

    background-color: var(--diary-box-detail-fundo);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
    padding:15px;
    width: 50%;
    height: 50%; 
    position: fixed;
    z-index: 1200;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}
.headerDetail{
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;


}
.headerDetail .iconeDetail{
    fill: var(--diary-box-detail-icone);
    width: 16px;
    height: 16px; 
    margin:0;
    text-transform: uppercase;
}

.bodyDetail{
/* scroll- */
    font-size: 0.8rem;
    text-transform: uppercase;
    height: 90%;
    overflow: auto;
    text-align: justify;

}
.bodyDetail p{
    padding-right: 10px;
}
.tableList{
    color: var(--diary-table-txt);
}
.tableIconeAction{
    fill: var(--diary-table-icone);
    cursor:pointer;
    width: 25px;
    height: 25px;
}
.tableIconeAction:hover{
      scale: 1.2;
      transition: 1s;
}
.tableIconeAction:first-child{
    margin-left: 10px;
}
.noneResult{
    align-items: center;
    justify-content: center;
    display: flex;
    height: 7rem;
    color: var(--diary-table-txt);
}

@media screen and (max-width: 500px){
    .tableList tbody tr{
        font-size: 0.7rem;
    }
    .tableList svg{
        width: 16px;
        height: 16px;
    }
}

@media screen and (max-width: 660px){
    .group{
        max-width: 650px; 
       
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 0px;
        margin: 0px;
    }
    .card{ 
        width: 200px;
        height: 150px;
        padding: 5px;
        margin-top: 8px;
    }
    .card:first-child{ 
        margin-top: 0px;
    }

    .titleTxt h5{
        font-size: 18px;
       
    }
    .titleTxt{
        padding-bottom: 0px;
       
    }
   
    .iconeCard{
        width: 22px;
        height: 22px;
        fill:var(--diary-card-icone);
        margin-bottom: 5px;
    }
    .bodyTxt{
        padding-left: 10px;
        font-size: 0.8rem;
    }

   
  
    .iconeActions{
        width: 16px;
        height: 16px;
        
    }
    .boxDetail{
        
        width: 80%;
        height: 70%; 
        
    }
   
    .bodyDetail{
    font-size: 0.7rem;
    text-transform: uppercase;
    height: 90%;
    overflow: auto;
    text-align: justify;
    
    }
    .bodyDetail p{
        padding-right: 10px;
    }

}
@media screen and (max-width: 990px){
    .group{
        max-width: 990px; 
       
       
    }
    .card{ 
        width: 200px;
        height: 150px;
        padding: 5px;
        
    }

    .titleTxt h5{
        font-size: 18px;
       
    }
    .titleTxt{
        padding-bottom: 0px;
       
    }
   
    .iconeCard{
        width: 22px;
        height: 22px;
        fill:var(--diary-card-icone);
        margin-bottom: 5px;
    }
    .bodyTxt{
        padding-left: 10px;
        /* font-size: 12px; */
        font-size: 0.7rem;
        
    }
   
  
    .iconeActions{
        width: 16px;
        height: 16px;
        
    }
    .boxDetail{
    
    }

}
