.cardInfor{
    max-width: 1350px;
    display: grid;
    grid-template-columns: 1fr;
    padding: 20px;
    margin: 0 auto;
    /* background-image: url("../../../../public/imagens/onda.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 30px; */

}
.body{

}
.txt{
  text-align: justify;
}