
.cardRadar{
  position: fixed;
  background-color: #000080;
  top: 220px;
  right: -171px;
  /* height: 50px; */
  border-radius: 15px 0px 0px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 5px;
  margin: 0;
  transition-duration: 2s;
}
.cardAcessibilidade{
    position: fixed;
    background-color: #000080;
    top: 278px;
    right: -109px;
    /* height: 50px; */
    border-radius: 15px 0px 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    padding: 5px;
    margin: 0;
    transition-duration: 2s;
    cursor: pointer;
}
.cardLibras{
  position: fixed;
  background-color: #000080;
  color:#fff;
  top: 336px;
  right: -50px;
  border-radius: 15px 0px 0px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 5px;
  margin: 0;
  transition-duration: 2s;
  cursor: pointer;
}
.cardAcessibilidade:hover{
  right: 0;
  transition-duration: 1.5s;

}
.cardLibras:hover{
  right: 0px;
  transition-duration: 1.5s;
}
.cardRadar:hover{
  right: 0;
  transition-duration: 1.5s;
  color: #ffff;

}
.cardRadar a{ 
  text-decoration: none;
  color: #ffff;
  display: flex;
  align-items: center;
}
.cardRadar span{
  margin-right: 0px 5px;
}
.cardAcessibilidade span{
  color: #fff;
}

.icone{
  width: 45px;
  height: 45px;
  padding: 3px;
  fill: #ffff;
  margin-right: 5px;
}


@media screen and (max-width: 400px) {

.radar:hover{
  right: -171px;

}
.cardAcessibilidade:hover{
  right: -109px;
}
.cardLibras{
  right: -50px;
}
}