.cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.box_card {
  position: relative;
}

.card {
  position: relative;
  width: 300px;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}

.representant_img {
  width: 100%;
  height: 230px;
  overflow: hidden;
}

.card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.card__details {
  background: #fff;
  padding: 20px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
}

.card__header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.card__thumb {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.card__header-text {
  flex: 1;
}

.card__title {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.card__status {
  font-size: 0.9rem;
  color: #777;
}

.card__description {
  margin-top: 10px;
}

.card__description p {
  margin: 5px 0;
}

.Button_details {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  color: #333;
  transition: color 0.3s ease;
}

.Button_details:hover {
  color: #555;
}

.Button_details p {
  margin: 0;
  font-weight: bold;
  margin-right: 5px;
}

.Button_details svg {
  transition: transform 0.3s ease;
}

.Button_details:hover svg {
  transform: rotate(90deg);
}

/* Estilo para o popup */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.card_plus {
  position: absolute;
  top: 0px;
  z-index: 10;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in-out;
  max-width: 340px;
  min-width: 340px;
  max-height: 80%;
  overflow-y: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.box-detalis {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.Title_card_plus {
  display: flex;
  align-items: center;
}

.title-plus-img {
  margin-right: 10px;
}

.tilte-plus-txt {
  display: flex;
  flex-direction: column;
}

.tilte-plus-txt h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.tilte-plus-txt .card__status {
  font-size: 0.9rem;
  color: #777;
}

.plus_icon_icon {
  cursor: pointer;
}

.icon_close_plus {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}

.plus_icon_icon:hover .icon_close_plus {
  transform: rotate(90deg);
}

.plus_txt {
  padding-top: 10px;
  font-size: 0.9rem;
  color: #333;
  line-height: 1.5;
}
